import React from 'react'
import Content from './content'
import Flex from '../Flex'
import Seo from '../Seo'
import Spacer from '../Spacer'
import MainInner from '../MainInner'
import ArrayReduceBanner from '../ArrayReduceBanner'
import shevy, { bs } from '../../shevy'
import { useNumber } from '@kyleshevlin/use-common'
import usePurchaseCourse from '../../hooks/usePurchaseCourse'
import Button from '../Button'
import { useProductsContext } from '../../contexts/ProductsContext'

export default function ArrayReducePurchasePage() {
  const arrayReduceProducts = useGetArrayReduceProducts()

  return (
    <>
      <Seo title="Purchase | Array.reduce()" />

      <article>
        <ArrayReduceBanner variant="small" />
        <MainInner>
          <Flex gap={bs(2)} wrap="wrap">
            <div>
              <Content />
            </div>
            <div css={{ flexGrow: 1 }}>
              <ValueChooser products={arrayReduceProducts} />
            </div>
          </Flex>
        </MainInner>
      </article>
    </>
  )
}

function useGetArrayReduceProducts() {
  const products = useProductsContext()
  const arrayReduceProducts = products.filter(
    product => product.course_id === 'array-reduce'
  )

  return arrayReduceProducts
}

// TODO: This isn't very reusable yet
function ValueChooser({ products }) {
  const [value, setValue] = useNumber(25)
  const productsByPrice = React.useMemo(() => {
    return products.reduce((acc, cur) => {
      acc[cur.price] = cur
      return acc
    }, {})
  }, [products])

  const handleChange = React.useCallback(
    e => {
      setValue.update(e.target.value)
    },
    [setValue]
  )

  // TODO: this component knows too much about the internals of a product
  const match = productsByPrice[value]
  const product = {
    quantity: 1,
    price: match?.stripe_price_id,
  }

  const { error, isLoading, sendToCheckout } = usePurchaseCourse(
    product,
    'array-reduce'
  )

  return (
    <div
      css={{
        border: '4px solid var(--colors-accent)',
        borderRadius: 8,
        padding: bs(1.5),
        textAlign: 'center',
      }}
    >
      <Flex direction="column" gap={bs()}>
        <label htmlFor="price">
          <Spacer bottom={1}>
            <div
              css={{
                fontFamily: 'var(--fonts-catamaran)',
                fontSize: shevy.h2.fontSize,
                fontWeight: 'bold',
              }}
            >
              Choose your value
            </div>
            <div css={{ fontSize: shevy.h2.fontSize }}>${value}</div>
          </Spacer>
          <input
            css={{ display: 'block', width: '100%' }}
            disabled={error || isLoading}
            min={5}
            max={50}
            step={5}
            id="price"
            name="price"
            type="range"
            value={value}
            onChange={handleChange}
          />
        </label>

        <div css={{ fontFamily: 'var(--fonts-catamaran)' }}>
          Move the slider left and right to adjust the value
        </div>

        {error && (
          <div>
            Something went wrong attempting to send you to checkout. Please try
            again later.
          </div>
        )}

        <BuyButton disabled={error || isLoading} onClick={sendToCheckout} />
      </Flex>
    </div>
  )
}

function BuyButton({ disabled, onClick }) {
  return (
    <Button
      css={{
        display: 'block',
        backgroundColor: 'var(--colors-contra)',
        borderRadius: 4,
        color: 'white',
        fontFamily: 'var(--fonts-catamaran)',
        fontSize: '1.75rem',
        lineHeight: 1.2,
        padding: bs(),
        textAlign: 'center',
        transition: 'background-color .3s ease',
        width: '100%',

        '&:hover': {
          backgroundColor: 'var(--colors-contraLight)',
          color: 'white',
        },
      }}
      disabled={disabled}
      onClick={onClick}
    >
      Checkout
    </Button>
  )
}
