import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get the course!`}</h2>
    <p>{`I believe in science. Studies show people value things they pay for `}<em parentName="p">{`more`}</em>{` than what they get for free. I want you to value this course.`}</p>
    <p>{`I also want you to feel `}<strong parentName="p">{`empowered`}</strong>{`. Empowered to get the education you need at a good price. `}<em parentName="p">{`And`}</em>{` also empowered to support your educator.`}</p>
    <p>{`It takes a lot of time and effort to make courses, even small ones. I want to keep making courses. I can't do that if people don't treat them as valuable.`}</p>
    <p>{`Choose your level of `}<strong parentName="p">{`value`}</strong>{` and `}<strong parentName="p">{`empowerment`}</strong>{` and enjoy the course.`}</p>
    <h2>{`What you'll get with your purchase`}</h2>
    <ul>
      <li parentName="ul">{`Full access to the course, plus any future updates`}</li>
      <li parentName="ul">{`An invite to my private Discord server`}
        <ul parentName="li">
          <li parentName="ul">{`Join a community of other learners`}</li>
          <li parentName="ul">{`Ask questions and get support as you learn`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      