import { useBool } from '@kyleshevlin/use-common'
import React from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import getStripe from '../utils/getStripe'

/**
 * Hook to set up the redirect to checkout for the product
 * @arg stripeProduct - An object of the product they are buying
 * @arg courseId - the id for the course they are buying
 */
export default function usePurchaseCourse(stripeProduct, courseId) {
  const [, { user }] = useAuthContext()
  const [isLoading, setIsLoading] = useBool()
  const [error, setError] = React.useState(null)

  const sendToCheckout = React.useCallback(async () => {
    if (!user) return

    setError(null)
    setIsLoading.on()

    try {
      // As the function suggests, we need to get our Stripe object
      const stripe = await getStripe()
      // We redirect to a checkout
      const { error: stripeError } = await stripe.redirectToCheckout({
        mode: 'payment',
        lineItems: [stripeProduct],
        successUrl: `${window.location.origin}/thank-you`,
        cancelUrl: window.location.href,
        // This is the best way I can think of at the moment to get the user id
        // and the correct course id to the webhook
        // It might be better to eventually upgrade this to create a session with
        // metadata, reference the session in this method call, and have that
        // metadata included in the webhook event
        clientReferenceId: `${user.id}:${courseId}`,
        customerEmail: user?.email,
      })

      if (stripeError) {
        console.error(stripeError)
        setError(stripeError)
      }
    } catch (err) {
      console.error(err)
      setError(err)
    }

    setIsLoading.off()
  }, [courseId, setIsLoading, stripeProduct, user])

  return { error, isLoading, sendToCheckout }
}
